function toogleAccordion1(e) {
  const parentButton = ((e.target.nodeName === "BUTTON")) ? e.target : e.target.parentElement;// eslint-disable-line
  const buttonToggle = parentButton.getAttribute("aria-expanded");
  const items1 = parentButton.parentElement.parentElement.querySelectorAll(".dw-accordion button");// eslint-disable-line
  items1.forEach((item) => {
    // Set attribute on elements
    if (item === parentButton && buttonToggle === "false") {
      item.setAttribute("aria-expanded", "true");
    } else {
      item.setAttribute("aria-expanded", "false");
    }
  });
}
function activateAccordions() {
  const items = document.querySelectorAll(".dw-accordion button");
  items.forEach((item) => item.addEventListener("click", toogleAccordion1));
}
document.addEventListener("DOMContentLoaded", () => {
  activateAccordions();
});
